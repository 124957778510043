<template>
  <div>
      <h1>Bind</h1>
      <v-btn @click="getModuleAuthUrl()">前往綁定官方帳號</v-btn>
  </div>
</template>

<script>
var host_url = process.env.VUE_APP_HOST_URL;

export default {
  name: "BindOA",

  components: {},
  mounted () {
    //   let params = new URL(location.href).searchParams;
  },
  methods: {
    async getModuleAuthUrl() {
        try {
            let resp = await axios.get(host_url + "/module/auth?format=json");
            console.log(resp.data.url);
            window.location.href = resp.data.url;
        } catch (error) {
            console.log(error);
            throw new Error('Login Fail')
        }
    }
  },
};
</script>
